<template>
  <section class="descr-list">
    <div class="container">
      <div class="descr-list__inner">
        <p class="descr-list__text">
          Мы проводим технический осмотр для водного транспорта:
        </p>
        <ul class="descr-list__list">
          <li class="descr-list__item">
            моторные лодки
          </li>
          <li class="descr-list__item">
            яхты
          </li>
          <li class="descr-list__item">
            гидроциклы
          </li>
          <li class="descr-list__item">
            катера
          </li>
          <li class="descr-list__item">
            и другие маломерные судна
          </li>
        </ul>
        <p class="descr-list__text">
          Мы проводим следующие типы техосмотров:
        </p>
        <ul class="descr-list__list">
          <li class="descr-list__item">
            первичный техосмотр — при первичной регистрации, купли/продажи судна
          </li>
          <li class="descr-list__item">
            очередной техосмотр — проводиться раз в 3-5 лет
          </li>
          <li class="descr-list__item">
            ежегодный техосмотр — проводится для подтверждения технического состояния судна один раз в год
          </li>
          <li class="descr-list__item">
            внеочередной техосмотр.
          </li>
        </ul>
        <p class="descr-list__text descr-list__text--small">
          После технического осмотра водного транспорта, судовладельцам выдается талон о<br> прохождении ТО.
        </p>   
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'BoatsList'
}
</script>
